import React, { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { Slide } from "@material-ui/core";
// import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
// import { Card } from "material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaymentPopUp({ price, plan, openModal }) {
  const [open, setOpen] = useState(openModal);
  //  const [timedOpen, setTimedOpen] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimedOpen(true);
  //   }, 3000);
  // }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {/* <Elements stripe={stripePromise} options={{ clientSecret }}> */}
          <CheckoutForm price={price} plan={plan} />
          {/* </Elements> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PaymentPopUp;
