import React from "react";
import {
  Container,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from "@material-ui/core";
import { useState } from "react";

const northwichServerData = {
  status: 200,
  data: {
    pageId: "1874Northwich",
    posts: [
      "3YLV7BoUHy7yNX94Tw5xBfU5tYFfDgLDtQJdNSHcyXEaauUJrE4K9vWf1XGgEEuDl",
      "E9p5he7ExTew3kWeAxooYtjG2AhSajuSwNfiH4yoqTN28eLT9pzLvSHNKkixZd1l",
      "hohpToXSd5hJmh4EjNLbwSM7xwV3Qc9iRVwMTnLvwrGUGG29TeFXuY9AJyxf7GXrl",
      "Qw5B9hM1VxXzgQpc126H3WkRyS7nZXdduRZ7bEcveaMz6wUSDPDDcpRhYoTM2356l",
      "2J2GiFuU8PLFQnuU4m6myEEMJZ5DPYakZ1rndi23MS2XsNghnMCu5eQpE9o2yQQ33l",
    ],
  },
};

const abbeyHeyServerData = {
  status: 200,
  data: {
    pageId: "abbeyheyfootballclub",
    posts: [
      "6jsV7BoUHy7yNX94Tw5xBfU5tYFfDgLDtQJdNSHcyXEaauUJrE4K9vWf1XGgE6jv",
      "Qw5B9hM1VxXzgQpc126H3WkRyS7nZXdduRZ7bEcveaMz6wUSDPDDcpRhYoTM2356l",
      "hohpToXSd5hJmh4EjNLbwSM7xwV3Qc9iRVwMTnLvwrGUGG29TeFXuY9AJyxf7GXrl",
      "2J2GiFuU8PLFQnuU4m6myEEMJZ5DPYakZ1rndi23MS2XsNghnMCu5eQpE9o2yQQ33l",
      "E9p5he7ExTew3kWeAxooYtjG2AhSajuSwNfiH4yoqTN28eLT9pzLvSHNKkixZd1l",
    ],
  },
};

const abbeyHultonServerData = {
  status: 200,
  data: {
    pageId: "abbeyhultonutd",
    posts: [
      "Qw5B9hM1VxXzgQpc126H3WkRyS7nZXdduRZ7bEcveaMz6wUSDPDDcpRhYoTM2356l",
      "hohpToXSd5hJmh4EjNLbwSM7xwV3Qc9iRVwMTnLvwrGUGG29TeFXuY9AJyxf7GXrl",
      "2J2GiFuU8PLFQnuU4m6myEEMJZ5DPYakZ1rndi23MS2XsNghnMCu5eQpE9o2yQQ33l",
      "6jsV7BoUHy7yNX94Tw5xBfU5tYFfDgLDtQJdNSHcyXEaauUJrE4K9vWf1XGgE6jv",
      "E9p5he7ExTew3kWeAxooYtjG2AhSajuSwNfiH4yoqTN28eLT9pzLvSHNKkixZd1l",
    ],
  },
};

const northwichSrcs = [
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1874Northwich%2Fposts%2Fpfbid023YLV7BoUHy7yNX94Tw5xBfU5tYFfDgLDtQJdNSHcyXEaauUJrE4K9vWf1XGgEEuDl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1874Northwich%2Fposts%2Fpfbid09E9p5he7ExTew3kWeAxooYtjG2AhSajuSwNfiH4yoqTN28eLT9pzLvSHNKkixZd1l&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1874Northwich%2Fposts%2Fpfbid02hohpToXSd5hJmh4EjNLbwSM7xwV3Qc9iRVwMTnLvwrGUGG29TeFXuY9AJyxf7GXrl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1874Northwich%2Fposts%2Fpfbid02Qw5B9hM1VxXzgQpc126H3WkRyS7nZXdduRZ7bEcveaMz6wUSDPDDcpRhYoTM2356l&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F1874Northwich%2Fposts%2Fpfbid02J2GiFuU8PLFQnuU4m6myEEMJZ5DPYakZ1rndi23MS2XsNghnMCu5eQpE9o2yQQ33l&show_text=true&width=500",
];

const abbeyHeySrcs = [
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyheyfootballclub%2Fposts%2Fpfbid0RSZTye2KbPYWRmXbUceQGf4camGztd97NQ4f5kXKzkbCMwwL1WEWeVicdiNQGiQ4l&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyheyfootballclub%2Fposts%2Fpfbid0CoymZNuDvuAJoavVVRY9yex9fvPyyQRkV6TFGT7hRu2sJM8CkAmxXBLacKTqBZipl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyheyfootballclub%2Fposts%2Fpfbid0YYHNaw1mPNFfdJoLg85FW7VJP7tYyif9JCfBHmhLFGyxrSPbByKo92viH5TxxE6Nl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyheyfootballclub%2Fposts%2Fpfbid0yWQgaJoKZscyxq1oD5L4zMs1aZXZQU3XtM4edcTJ7GvcJYkiqXjv11tS7H5geQhcl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyheyfootballclub%2Fposts%2Fpfbid02z8SszjKgezCEgtgnakXFjRpuFNUTffU4vPaWsXUQub3NaNgd5xx1JxRHdUGBXPC9l&show_text=true&width=500",
];

const abbeyHultonSrcs = [
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyhultonutd%2Fposts%2Fpfbid0ATo1mR7AQzcvgPUycpi7rkBNsrzfDADwZtwJGzXLzAhTWvPgi5CRzhTdjdf5Xuj9l&show_text=true&width=500",
  "https://www.facebook.com/plugins/video.php?height=308&href=https%3A%2F%2Fwww.facebook.com%2Fabbeyhultonutd%2Fvideos%2F1068294750497806%2F&show_text=false&width=560&t=0",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyhultonutd%2Fposts%2Fpfbid035wuWryTHKTLJysCJHFoJegZX1Eb192ncLpnU4eFc3W5b9t3TKcjTohXyW6Knsp1Yl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyhultonutd%2Fposts%2Fpfbid023axXjHZAR3z2QPEQ89maLsFFx1MpQbsxyasPdArTDDPT4cY9TxJ8fVyBqzhV7Pbjl&show_text=true&width=500",
  "https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fabbeyhultonutd%2Fposts%2Fpfbid028misyS6V9T6ca8EWcmUYQUkWkT14hNrkLYqkB1uzEn7c27iQ66QHun7aEvfZ2Hn2l&show_text=true&width=500",
];

export default function TeamSocials() {
  const [selectedTeam, setSelectedTeam] = useState();

  const handleChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const renderIFrames = (srcs) => {
    return srcs.map((src) => (
      <div key={src}>
        <iframe
          src={src}
          width="500"
          height="377"
          // style="border:none;overflow:hidden"
          scrolling="no"
          frameBorder="0"
          // allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      </div>
    ));
  };

  return (
    <Container>
      <Box padding={12}>
        <Typography style={{ fontWeight: "bold", fontSize: "24px" }}>
          Step 1: User selects a team.
        </Typography>

        <Typography style={{ fontWeight: "bold", fontSize: "24px" }}>
          Step 2: Client reaches out to the server to fetch latest posts of that
          team.
        </Typography>

        <Typography style={{ fontWeight: "bold", fontSize: "24px" }}>
          Step 3: User uses the data as embedded Facebook posts.
        </Typography>

        <FormControl fullWidth>
          <InputLabel id="team">Select Team</InputLabel>
          <Select labelId="team" value={selectedTeam} onChange={handleChange}>
            <MenuItem value={1}>1874 Northwich</MenuItem>
            <MenuItem value={2}>Abbey Hey FC</MenuItem>
            <MenuItem value={3}>Abbey Hulton United</MenuItem>
          </Select>
        </FormControl>

        <Box mt={4}>
          <Paper style={{ padding: 8 }}>
            <Typography variant="h6" component="div">
              Server Response
            </Typography>

            <div
              style={{
                overflowWrap: "anywhere",
                display: selectedTeam === 1 ? "block" : "none",
              }}
            >
              {JSON.stringify(northwichServerData.data)}
            </div>
            <div
              style={{
                overflowWrap: "anywhere",
                display: selectedTeam === 2 ? "block" : "none",
              }}
            >
              {JSON.stringify(abbeyHeyServerData.data)}
            </div>
            <div
              style={{
                overflowWrap: "anywhere",
                display: selectedTeam === 3 ? "block" : "none",
              }}
            >
              {JSON.stringify(abbeyHultonServerData.data)}
            </div>
          </Paper>
        </Box>

        <Box mt={4}>
          <Paper style={{ padding: 8 }}>
            <Typography variant="h6" component="div">
              Facebook Posts
            </Typography>

            <div style={{ display: selectedTeam === 1 ? "block" : "none" }}>
              {renderIFrames(northwichSrcs)}
            </div>

            <div style={{ display: selectedTeam === 2 ? "block" : "none" }}>
              {renderIFrames(abbeyHeySrcs)}
            </div>

            <div style={{ display: selectedTeam === 3 ? "block" : "none" }}>
              {renderIFrames(abbeyHultonSrcs)}
            </div>

            {/* <div
              className="fb-post"
              data-href="https://www.facebook.com/20531316728/posts/10154009990506729/"
              data-width="500"
              data-show-text="true"
            >
              <blockquote
                cite="https://www.facebook.com/20531316728/posts/10154009990506729/"
                className="fb-xfbml-parse-ignore"
              >
                Posted by{" "}
                <a href="https://www.facebook.com/facebook/">Facebook</a>{" "}
                on&nbsp;
                <a href="https://www.facebook.com/20531316728/posts/10154009990506729/">
                  Thursday, 27 August 2015
                </a>
              </blockquote>
            </div> */}
          </Paper>
        </Box>
      </Box>
    </Container>
  );
}
