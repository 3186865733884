import Contact from './Contact'

const contactUsRoutes = [
    {
        path: '/contact',
        exact: true,
        component: Contact
    }
]

export default contactUsRoutes