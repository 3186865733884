import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";
// import FaqDataInfo from './FaqDataInfo';


const FaqDataInfo = EgretLoadable({
  loader: () => import("./Faq"),
});

const FaqRoutes = [
    {
        path: "/faq",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <FaqDataInfo /> : <Redirect to='/session/signin' />
    },
]

export default FaqRoutes;